footer {
  /* background-color: #f0f0f0; Added a background color to see the footer */
  padding: 20px;
  text-align: center;
  bottom: 0;
  margin: 8% auto 0 auto;
}

.footer-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
}

.center-icon-container {
  position: absolute;
  flex-grow: 1;
}

.center-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .footer-container {
    flex-direction: column; /* Stack items vertically */
    align-items: center;
  }

  .center-icon-container {
    position: static; /* Remove absolute positioning */
    margin-top: 10px; /* Add spacing between items */
  }

  .center-icon {
    position: static; /* Remove absolute positioning */
    transform: none; /* Remove the transform */
  }
}

/* Media query for medium screens (e.g., tablets) */
@media (max-width: 1200px) {
  .footer-container {
    flex-direction: column; /* Stack items vertically */
    align-items: center;
  }

  .center-icon-container {
    position: static; /* Remove absolute positioning */
    margin-top: 10px; /* Add spacing between items */
  }

  .center-icon {
    position: static; /* Remove absolute positioning */
    transform: none; /* Remove the transform */
  }
}
