/* .notes-card-container {
  position: relative;
} */

.notes-card {
  position: absolute;
  width: 50vh;
}

.orgNotes-container {
  overflow-wrap: break-word;
  overflow-y: auto;
  border: 1px solid #273a9158;
  border-radius: 4px;
  min-height: 25vh;
  max-height: 25vh;
}

/* Style for task details view ~~~~~~~~~~ */
.notes-card-task-view {
  position: absolute;
  margin-left: 3vh;
  width: 50vh;
  height: 60vh;
}

.orgNotes-container-task-view {
  overflow-wrap: break-word;
  overflow-y: auto;
  border: 1px solid #273a9158;
  border-radius: 4px;
  max-height: 500px;
}
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.note-main-container {
  padding-left: 25px;
}

/* For color of every other <li> */
.note-main-container:nth-child(odd) {
  background-color: rgb(243, 243, 243);
}

.note-main-container:nth-child(even) {
  background-color: rgb(254, 254, 254);
}

.notes-delete-btn:hover {
  color: red;
}

@media (max-width: 1000px) {
  .notes-card-container {
    display: none;
  }
}

@media (max-width: 400px) {
  .notes-card-container {
    display: none;
  }
  .notes-card {
    display: none;
  }
}
