.add-group-fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.popover-container {
  width: 100%;
}

@media (max-width: 400px) {
  #add-group-button {
    font-size: 10px;
    margin-top: 20px;
  }
}
