.detailsView-container {
  margin-top: 15px;
  position: relative;
}

.details-card {
  width: 90%;
  margin: 20px auto 0 auto;
  position: relative;
}

.OrgDetailsCard-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  margin-top: 25px;
  padding-top: 55px;
}

/* .OrgDetailsCard-container {
  border: 1px solid #19338e;
  border-radius: 5px;
} */

.MerchantDetailsCard-container {
  /* width: 58vw; */
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 40px;
  margin-top: 25px;
  padding-top: 55px;
}

.add-group-fields {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popover-container {
  margin-right: 3%;
}

@media (max-width: 400px) {
  .details-container,
  .detailsView-container,
  .details-card {
    width: 100%;
  }

  .add-group-btn {
    justify-content: center;
    margin-top: 15px;
  }

  .OrgGroupInfo-container {
    flex-direction: column;
  }
}
