.NavLinks-container {
  display: flex;
  justify-content: space-between;
  width: 70vw;
  margin: 20px auto 0 auto;
  font-family: Telugu Sangam MN;
}

.main-navlink {
  color: black;
  text-decoration: none;
}

.main-navlink:hover {
  color: #273b91;
}

@media (max-width: 1200px) {
  .NavLinks-container {
    display: none;
  }
}

@media (max-width: 400px) {
  .NavLinks-container {
    display: none; /* Hide the navbar on really small screens */
  }
}
