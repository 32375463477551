.details-view-card {
  margin-top: 30px;
  /* height: 650px; */
  width: 400px;
  padding: 10px;
}

.details-view-card:hover {
  cursor: pointer;
  transform: scale(1.03);
}

.group-info {
  margin-top: 15px;
}

.no-photo-container {
  max-width: 100%; /* Allow the container to shrink as the screen size decreases */
  height: 225px; /* Maintain the aspect ratio */
  max-height: 225px; /* Set a maximum height if needed */
  width: 100%; /* Take up the full width of the parent container */
  object-fit: contain;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dadadc;
  border: 1px dotted rgb(96, 93, 93);
}

@media (max-width: 1000px) {
  #orgDetails-Card {
    width: 100%;
  }
  #orgDetails-Card:hover {
    transform: none;
  }
}

@media (max-width: 400px) {
  #orgDetails-Card {
    width: 100%;
    padding: 0;
  }

  #orgDetails-Card:hover {
    transform: none;
  }
}
