.goals-display-card {
  position: absolute;
  width: 50vh;
  right: 0;
  top: 0;
  /* height: 40vh; */
}

.org-detail-goal-container {
  /* height: 28vh;
  max-height: 32vh; */
  overflow-y: auto;
  border: 1px solid #273a9158;
  border-radius: 4px;
}

/* Container for when there are no active fundraisers */
.no-fundraisers-bg {
  background-color: rgb(243, 243, 243);
}

.no-fundraisers-container {
  padding: 20%;
  background-color: rgb(243, 243, 243);
}
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

@media (max-width: 1000px) {
  .goals-display-card {
    display: none;
  }
}
