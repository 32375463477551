/* --------------------------------------------------------------------------------
PSG Logo image
-------------------------------------------------------------------------------- */
.psg-logo {
  display: flex;
  justify-content: center;
}

.psg-logo img {
  /* height: 200px;
  width: 400px; */
  height: 90px;
  width: 200px;
}
