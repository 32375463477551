.organizationsContainer {
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.fuzzy-search {
  display: flex;
  justify-content: flex-end;
  margin-right: 1%;
  margin-top: -52px;
}
