.active_table_cell {
    border: 2px solid black; 
    min-width: 200px; 
    height: 30px; 
    padding: 0px;
    text-align: center !important;

}

.active_table_cell ul {
    list-style-type: none; /* Remove bullet points */
    padding: 0; /* Remove default padding for ul */
}

.active_row:nth-child(even) {
    background-color: rgb(232, 232, 232);
}