.task-display-container,
.merchant-task-view {
  /* height: 46vh; */
  max-height: 65vh;
  overflow-y: auto;
  border: 1px solid #273a9158;
  border-radius: 4px;
}

.task-display-container {
  height: 46vh;
}

.merchant-task-view {
  height: auto;
}
